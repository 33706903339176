import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// components
import Fragranze from "../components/fragranze";
import Personalizza from "../components/personalizza";
import SalvaFreschezza from "../components/salvafreschezza";
import Hero from "../utils/hero";
import Link from "../utils/link";
import Page from "../utils/page";
import Section from "../utils/section";
import theme from "../utils/theme";

const WhiteButton = styled(Button)(({ theme }) => ({
  border: "none",
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.getContrastText(theme.palette.primary.main),
  "&:hover": {
    border: "none",
    backgroundColor: theme.palette.grey[200],
  },
}));

// markup
const HomePage = () => {
  return (
    <Page
      title="Salviette profumate monouso"
      description="Dal 1998 produciamo salviette profumate monouso. Le nostre salviettine umidificate singole sono prodotte con un particolare tessuto rendendole resistenti e allo stesso tempo delicate sulla pelle."
      type="WebPage"
      readMore={["/salviette-monouso"]}
      image="/home.jpg"
    >
      <Hero
        title="Dal 1998 produciamo salviette profumate monouso per il mondo Ho.Re.Ca"
        description={
          <>
            Le nostre <strong>salviettine umidificate singole</strong> sono
            prodotte con un particolare tessuto rendendole{" "}
            <strong>resistenti</strong> e allo stesso tempo{" "}
            <strong>delicate sulla pelle</strong>. Sono ideali per pulire le
            mani alla fine di un pasto.
            <Box pt={4}>
              <Link
                type="internal"
                href="/salviette-monouso"
                title="Scopri di più sulle salviette"
              >
                <WhiteButton size="large" variant="contained" color="primary">
                  Le nostre salviette
                </WhiteButton>
              </Link>
            </Box>
          </>
        }
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../images/backgrounds/fragranza-menta.jpg"
            alt="Fragranza menta"
            layout="fullWidth"
          />
        }
        image={
          <StaticImage
            imgStyle={{ borderRadius: 16 }}
            src="../images/products/salviette-monouso-per-la-ristorazione-mettifogo.png"
            alt="Salviette monouso per bar, ristoranti e hotel"
            layout="fullWidth"
          />
        }
      />

      <Fragranze />
      <SalvaFreschezza />

      <Section
        maxWidth="md"
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../images/backgrounds/fragranza-menta.jpg"
            alt="Fragranza menta"
            layout="fullWidth"
          />
        }
      >
        <Stack spacing={2} textAlign="center">
          <Typography variant="h3" component="h2">
            Salviette profumate monouso,
            <br /> perfette per bar, ristoranti e hotel
          </Typography>
          <Typography>
            Scopri le varie profumazioni e trova quella che si addice meglio al
            tuo locale, bar o ristorante.
          </Typography>

          <Box>
            <Link
              type="internal"
              href="/salviette-monouso"
              title="Scopri di più sulle salviette"
            >
              <Button size="large" variant="contained">
                Scopri di più
              </Button>
            </Link>
          </Box>
        </Stack>
      </Section>

      <Section>
        <Grid
          spacing={6}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5}>
            <StaticImage
              imgStyle={{ borderRadius: 16 }}
              src="../images/mettifogo-produttore-salviette-personalizzate.jpg"
              alt="Confezioni salvafreschezza"
              layout="fullWidth"
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">
                Chi siamo
              </Typography>
              <Typography>
                Nel 1989 abbiamo iniziato a produrre{" "}
                <strong>prodotti personalizzati per la ristorazione</strong>,
                aggiungendo nell'anno 1998 la linea di produzione per le
                salviette umide monouso.
              </Typography>
              <Typography>
                Partendo dalle classiche salviette limone monouso, abbiamo poi
                creato una vasta gamma di profumazioni.
              </Typography>
              <Typography>
                Le nostre <strong>salviettine umidificate singole</strong> sono
                state progettate per durare nel tempo, grazie alla qualità
                dell'involucro esterno, studiato per conservare l'umidità ed il
                profumo della fragranza.
              </Typography>
              <Box>
                <Link
                  type="internal"
                  href="/chi-siamo"
                  title="Scopri di più chi siamo"
                >
                  <Button
                    style={{ padding: 8, marginLeft: -8 }}
                    size="large"
                    variant="text"
                    color="info"
                    endIcon={<ArrowForwardIcon />}
                  >
                    Scopri di più
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Section>

      <Personalizza />
    </Page>
  );
};

export default HomePage;
